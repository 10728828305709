import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  title: string
  URL?: string
  weight?: number
}

export const Room = memo(function Room({ image, URL }: Props) {
  return (
    <CTA to={URL ? URL : ''}>
      {image ? <Background className="swiper-lazy" {...image} /> : null}
    </CTA>
  )
})

const CTA = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 77.7vh;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1023px) {
    height: 60vh;
  }
`

const Background = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`
