import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Item = memo(function Item({ icon, label }: Props) {
  return (
    <Container>
      {icon ? <SVG src={icon} width="57" height="49" alt={label} /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled.div`
  max-width: 8.75rem;
  margin: 0 3.875rem;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
    max-width: none;
    margin: 2.25rem 0 0;
    padding: 0 1.5rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const SVG = styled.img`
  width: auto;
  height: 3rem;

  @media (max-width: 1023px) {
    height: 2.25rem;
  }
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin-top: 1.5rem;
`
