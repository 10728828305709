import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Separator } from 'app/components/Common/Separator'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Link } from 'gatsby'
import React, { memo, useState } from 'react'
import * as Icon from 'react-feather'
import Fade from 'react-reveal/Fade'
import SwiperCore, {
  Autoplay,
  Controller,
  EffectFade,
  Lazy,
  Navigation,
  Thumbs,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Props as RoomProps, Room } from './Room'

SwiperCore.use([Autoplay, Controller, EffectFade, Lazy, Navigation, Thumbs])

export interface Props {
  cta?: ButtonProps[]
  rooms?: RoomProps[]
  subtitle?: string
  title?: string
}

export const RoomsSlider = memo(function RoomsSlider({
  cta,
  subtitle,
  rooms,
  title,
}: Props) {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)

  return (
    <Container>
      <Separator />
      <Fade bottom distance="3.75rem">
        <>
          <Text>
            {title ? <Title>{title}</Title> : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          </Text>
          <Slider>
            {rooms ? (
              <>
                <Swiper
                  allowTouchMove={false}
                  /* autoplay={{ delay: 4000 }} */
                  controller={{ control: thumbsSwiper }}
                  effect="fade"
                  lazy
                  loop={rooms.length > 1 ? true : false}
                  navigation={{
                    nextEl: `.rooms-slider-next`,
                    prevEl: `.rooms-slider-prev`,
                  }}
                  preloadImages={false}
                  thumbs={{ swiper: thumbsSwiper }}
                  watchSlidesProgress
                >
                  {rooms.map((item, index) => (
                    <SwiperSlide key={index}>
                      <Room {...item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                {rooms.length > 1 ? (
                  <>
                    <SliderControls>
                      {rooms ? (
                        <Swiper
                          allowTouchMove={false}
                          centeredSlides={true}
                          loop={rooms.length > 1 ? true : false}
                          onSwiper={setThumbsSwiper}
                          simulateTouch={false}
                          watchSlidesVisibility={true}
                          watchSlidesProgress
                        >
                          {rooms.map((item, index) => (
                            <SwiperSlide key={index}>
                              {item.URL ? (
                                <Link to={item.URL}>{item.title}</Link>
                              ) : (
                                item.title
                              )}
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      ) : null}
                      <Arrows row space="between">
                        <Arrow dial={5} className={`rooms-slider-prev`}>
                          <Icon.ChevronLeft />
                        </Arrow>
                        <Arrow dial={5} className={`rooms-slider-next`}>
                          <Icon.ChevronRight />
                        </Arrow>
                      </Arrows>
                    </SliderControls>
                  </>
                ) : null}
              </>
            ) : null}
            {cta
              ? cta.map((item, index) => (
                  <CTA key={index} {...item} variant="corners" />
                ))
              : null}
          </Slider>
        </>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  margin: 6rem 0;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 3.75rem 0;
  }
`

const Text = styled.div`
  max-width: 34.625rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  margin: auto;
  padding: 2.25rem 1.5rem;
  position: relative;
  z-index: 2;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.75rem;
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin-top: 1.125rem;
  text-transform: uppercase;
`

const Slider = styled.div`
  overflow: hidden;
  margin-top: -6.125rem;
  position: relative;
`

const SliderControls = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 3;
  transform: translateY(-50%);

  .swiper-slide {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: 3.125rem;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 4rem;
    opacity: 0.23;
    text-transform: uppercase;
    &.swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    .swiper-slide {
      font-size: 1.875rem;
      line-height: 2.9375rem;
    }
  }
`

const Arrows = styled(FlexBox)`
  width: 59.375rem;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);

  @media (max-width: 1199px) {
    width: 47.5rem;
  }

  @media (max-width: 1023px) {
    width: 42.5rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Arrow = styled(FlexBox)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 0.375rem;
  transform: translateY(-50%);
  &:first-of-type {
    left: 0;
  }
  &:last-of-type {
    right: 0;
  }
  svg {
    width: auto;
    height: 5.375rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 0.4;
    position: absolute;
    top: -1.25rem;
    left: -1.25rem;
  }
`

const CTA = styled(Button)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 0;
  position: absolute;
  bottom: 4.375rem;
  left: 50%;
  z-index: 3;
  transform: translateX(-50%);
`
